import '../scss/main.scss';
import '../scss/office.scss';
import Vue from './_vue-signleton.js';

import './_common.js';

Vue.setOptions({
  delimiters: ['${', '}'],
  el: '#content',
  data: function() {
    return {
      otherOffices: false,
      accordionContainer: null,
      activeAccordionEl: null
    };
  },

  mounted: function() {
    // Grab the long accordion as a vue component.
    let accordion = this.$children.find((inst) => inst.$el.classList.contains('accordion-section'));

    if (accordion) {
      accordion.$on('toggle', this.onOfficeToggle);

      if (accordion.$el.classList.contains('long-list')) {
        this.accordionContainer = document.createElement('div');
        this.accordionContainer.classList.add('accordion-description-container');
        accordion.$el.appendChild(this.accordionContainer);
        accordion.$on('toggle', this.revealOfficeDetails);
      }

      // If we have a #city hash, open it and scroll to it.
      if (window.location.hash) {
        let selector = `.accordion-trigger[data-hash="${window.location.hash.replace(/^#/, '')}"`,
          city = this.$el.querySelector(selector);

        if (city) {
          // If we have a hash trigger a click on the accordion so it expands and scroll to it.
          city.click();
          this.$nextTick(() => {
            window.scrollTo(0, accordion.$el.getBoundingClientRect().top);
          });
        }
      }
    }
  },

  methods: {
    /**
     * Track the open city in the URL for deep-linking
     * @param {Element} descriptionEl The description element.
     * @param {Boolean} active If the accordion is active or not.
     */
    onOfficeToggle(descriptionEl, active) {
      // Use replaceState instead of location.hash so we don't have page scroll.
      if (active) {
        window.history.replaceState(
          {},
          window.document.title,
          window.location.pathname + '#' + descriptionEl.dataset.hash
        );
      } else {
        window.history.replaceState({}, window.document.title, window.location.pathname);
      }
    },

    showOtherOffices() {
      this.otherOffices = !this.otherOffices;
    },

    /**
     * Expand and toggle the accordion.
     * @param {Element} descriptionEl The description element.
     */
    revealOfficeDetails(descriptionEl) {
      if (descriptionEl === this.activeAccordionEl) {
        this.accordionContainer.classList.toggle('active');
      } else {
        this.accordionContainer.innerHTML = descriptionEl.innerHTML;
        this.accordionContainer.classList.add('active');

        this.$nextTick(() => {
          var rect = this.accordionContainer.getBoundingClientRect();
          let winTop = (window.innerHeight || document.documentElement.clientHeight) - 200;
          if (rect.top > winTop) {
            window.scrollTo(0, rect.top);
          }
        });
      }
      this.activeAccordionEl = descriptionEl;
    }
  }
});
